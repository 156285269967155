import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './App.css';
import AppRoutes from './Routes.js';
import 'antd/dist/reset.css';
import './css/modal.css';
import './css/tasklocation.css';


function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '3b8eb9',
          colorLink: '3b8eb9',
          colorText: '#333333',
          colorError: '#D92D20',
        }
      }}
    >
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;