import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './Layouts/MainLayout';
import Home from './pages/Home';

import VehicleListOld from './pages/Vehicle/OldVehicleList';
import VehicleList from './pages/Vehicle/VehicleList';
import EditVehicle from './pages/Vehicle/EditVehicle';
import AddVehicle from './pages/Vehicle/AddVehicle';

import TaskLocation from './pages/TaskLocation';
import AddLocation from './pages/TaskLocation/AddLocation';
import EditLocation from './pages/TaskLocation/EditLocation';


import Settings from './pages/Settings';
import Tools from './pages/Tools';
import Help from './pages/Help';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="vehicle" element={<VehicleList />} />
        <Route path="vehicle-old" element={<VehicleListOld />} />
        <Route path="/edit-vehicle/:make/:model/:type/:NoOfVehicles" element={<EditVehicle />} />
        <Route path="add-vehicle" element={<AddVehicle />} />
        <Route path="task-location" element={<TaskLocation />} />
        <Route path="add-location" element={<AddLocation />} />
        <Route path="/edit-location/:taskLocation/:order" element={<EditLocation />} />
        <Route path="settings" element={<Settings />} />
        <Route path="tools" element={<Tools />} />
        <Route path="help" element={<Help />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;