import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Table, Input, Button, Flex, Typography, Modal, Select, Tooltip, Popconfirm, Checkbox
} from 'antd';
import { Link } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ResizableBox } from 'react-resizable';
import eCoachVMSApi from '../../Api';

import { 
  StepBackwardOutlined, CaretLeftOutlined, CaretRightOutlined,
  StepForwardOutlined, FilterOutlined, EditOutlined,
  DeleteOutlined, CaretUpOutlined, CaretDownOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const PAGE_SIZE = 50;
const ITEM_TYPE = 'ROW';

// Memoized DraggableTableRow component
// Draggable Row Component
const DraggableTableRow = ({ index, moveRow, children, ...restProps }) => {
  const ref = React.useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} data-handler-id={handlerId} {...restProps}>
      {children}
    </tr>
  );
};

// Memoized ColumnDrag component
const ColumnDrag = React.memo(({ columnKey, moveColumn, children }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'column',
    item: { columnKey },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'column',
    hover: (item) => {
      if (item.columnKey !== columnKey) {
        moveColumn(item.columnKey, columnKey);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ 
        cursor: 'move', 
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#f0f0f0' : 'transparent',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <ResizableBox
        width={110}
        height={16}
        minConstraints={[80, 16]}
        maxConstraints={[300, 16]}
        resizeHandles={['e']}
      >
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {children}
        </div>
      </ResizableBox>
    </div>
  );
});

const DEFAULT_COLUMNS_ORDER = ['Make', 'Model', 'Type', 'NoOfVehicles'];
const DEFAULT_COLUMNS_VISIBILITY = {
  Make: true,
  Model: true,
  Type: true,
  NoOfVehicles: true
};

const VehicleList = () => {
  const { baseURL } = eCoachVMSApi();
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [columnsOrder, setColumnsOrder] = useState(DEFAULT_COLUMNS_ORDER);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);
  const [filterType, setFilterType] = useState('startWith');
  const [filterValue, setFilterValue] = useState('');
  const [currentColumn, setCurrentColumn] = useState('');
  const [activeFilters, setActiveFilters] = useState({});
  const [columnsVisibility, setColumnsVisibility] = useState(DEFAULT_COLUMNS_VISIBILITY);
  const [tempColumnsVisibility, setTempColumnsVisibility] = useState(DEFAULT_COLUMNS_VISIBILITY);

  // Data fetching with cleanup
  useEffect(() => {
    let isMounted = true; 
    async function getMakeModelList() {
      try {
        const response = await baseURL.get('/getMakeModelList');
        if (isMounted) {
          setOriginalData(response.data || []);
        }
      } catch (error) {
        console.error('Failed to fetch make/model list:', error);
      }
    }
    getMakeModelList();
    return () => {
      isMounted = false;
    };
  }, []);

  // Memoized handlers
  const handleSearch = useCallback((value) => {
    setSearchText(value);
    setCurrentPage(1);
  }, []);

  const handleDelete = useCallback((key) => {
    setOriginalData(prev => prev.filter(item => `${item.Make}-${item.Model}` !== key));
  }, []);

  const handleFilterOpen = useCallback((column) => {
    setCurrentColumn(column);
    setFilterType(activeFilters[column]?.type || 'startWith');
    setFilterValue(activeFilters[column]?.value || '');
    setIsFilterModalVisible(true);
  }, [activeFilters]);

  const handleSettingOpen = useCallback(() => {
    setIsSettingModalVisible(true);
  }, []);

  const handleSettingClose = useCallback(() => {
    setIsSettingModalVisible(false);
  }, []);

  const handleColumnVisibilityChange = useCallback((columnKey) => {
    setTempColumnsVisibility(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  }, []);

  const handleSaveSettings = useCallback(() => {
    setColumnsVisibility(tempColumnsVisibility);
    setIsSettingModalVisible(false);
  }, [tempColumnsVisibility]);

  const handleResetSettings = useCallback(() => {
    setColumnsOrder(DEFAULT_COLUMNS_ORDER);
    setColumnsVisibility(DEFAULT_COLUMNS_VISIBILITY);
    setTempColumnsVisibility(DEFAULT_COLUMNS_VISIBILITY);
    setSortField(null);
    setSortOrder(null);
    setActiveFilters({});
    setFilterValue('');
    setFilterType('startWith');
    setSearchText('');
    setCurrentPage(1);
  }, []);

  const applyFilter = useCallback(() => {
    setActiveFilters(prev => ({
      ...prev,
      [currentColumn]: { type: filterType, value: filterValue }
    }));
    setCurrentPage(1);
    setIsFilterModalVisible(false);
  }, [currentColumn, filterType, filterValue]);

  const clearFilter = useCallback(() => {
    setActiveFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[currentColumn];
      return newFilters;
    });
    setCurrentPage(1);
    setIsFilterModalVisible(false);
  }, [currentColumn]);

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const draggedRowIndex = startIndex + dragIndex;
    const hoverRowIndex = startIndex + hoverIndex;
    
    setOriginalData(prev => {
      const newData = [...prev];
      const [removedItem] = newData.splice(draggedRowIndex, 1);
      newData.splice(hoverRowIndex, 0, removedItem);
      return newData;
    });
  }, [currentPage]);

  const moveRowold = useCallback((dragIndex, hoverIndex) => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const draggedRowIndex = startIndex + dragIndex;
    const hoverRowIndex = startIndex + hoverIndex;
    
    setOriginalData(prev => {
      const newData = [...prev];
      const [removed] = newData.splice(draggedRowIndex, 1);
      newData.splice(hoverRowIndex, 0, removed);
      return newData;
    });
  }, [currentPage]);

  const moveColumn = useCallback((fromKey, toKey) => {
    setColumnsOrder(prev => {
      const fromIndex = prev.indexOf(fromKey);
      const toIndex = prev.indexOf(toKey);
      const newOrder = [...prev];
      const [removed] = newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, removed);
      return newOrder;
    });
  }, []);

  // Memoized filtered data
  const getFilteredData = useMemo(() => {
    let result = [...originalData];
    
    if (searchText.trim()) {
      const searchLower = searchText.toLowerCase();
      result = result.filter((record) =>
        Object.values(record).some((val) =>
          String(val).toLowerCase().includes(searchLower)
        )
      );
    }

    Object.entries(activeFilters).forEach(([column, { type, value }]) => {
      if (value.trim()) {
        const filterLower = value.toLowerCase();
        result = result.filter((record) => {
          const fieldValue = String(record[column]).toLowerCase();
          switch (type) {
            case 'startWith':
              return fieldValue.startsWith(filterLower);
            case 'contain':
              return fieldValue.includes(filterLower);
            case 'isEqual':
              return fieldValue === filterLower;
            case 'notEqual':
              return fieldValue !== filterLower;
            default:
              return true;
          }
        });
      }
    });

    if (sortField && sortOrder) {
      result.sort((a, b) => {
        let comparison = 0;
        if (typeof a[sortField] === 'number') {
          comparison = a[sortField] - b[sortField];
        } else {
          comparison = String(a[sortField]).localeCompare(String(b[sortField]));
        }
        return sortOrder === 'ascend' ? comparison : -comparison;
      });
    }

    return result;
  }, [originalData, searchText, activeFilters, sortField, sortOrder]);

  // Memoized paginated data
  const processedData = useMemo(() => {
    const totalPages = Math.ceil(getFilteredData.length / PAGE_SIZE);
    const validatedPage = Math.min(Math.max(1, currentPage), Math.max(1, totalPages));
    
    if (validatedPage !== currentPage) {
      setTimeout(() => setCurrentPage(validatedPage), 0);
      return [];
    }
    
    const startIndex = (validatedPage - 1) * PAGE_SIZE;
    return getFilteredData.slice(startIndex, startIndex + PAGE_SIZE);
  }, [getFilteredData, currentPage]);

  // Memoized columns configuration
  const columns = useMemo(() => {
    const handleSort = (field) => {
      if (sortField === field) {
        if (sortOrder === 'ascend') {
          setSortOrder('descend');
        } else if (sortOrder === 'descend') {
          setSortField(null);
          setSortOrder(null);
        } else {
          setSortOrder('ascend');
        }
      } else {
        setSortField(field);
        setSortOrder('ascend');
      }
    };

    return columnsOrder.map((columnKey) => ({
      title: (
        <ColumnDrag columnKey={columnKey} moveColumn={moveColumn}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8 }}>
              {columnKey === 'NoOfVehicles' ? 'No Of Vehicles' : columnKey}
            </span>
            {sortField === columnKey && (
              <>
                {sortOrder === 'ascend' ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </>
            )}
            <Tooltip title="Filter">
              <Button
                type="text"
                size="small"
                className='filter'
                icon={<FilterOutlined />}
                onClick={() => handleFilterOpen(columnKey)}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          </div>
        </ColumnDrag>
      ),
      dataIndex: columnKey,
      key: columnKey,
      onHeaderCell: (column) => ({
        className: `drag-th ${column.key}`,
        onClick: () => handleSort(columnKey),
        style: {
          display: columnsVisibility[columnKey] ? 'table-cell' : 'none',
        },
      }),
      onCell: (record) => ({
        className: `${columnKey}`,
        style: {
          display: columnsVisibility[columnKey] ? 'table-cell' : 'none',
        },
      }),
      render: (text) => <div className={`${columnKey}`}>{text}</div>
    })).concat([
      {
        title: '',
        key: 'edit',
        onHeaderCell: () => ({
          className: 'actions',
        }),
        onCell: () => ({
          className: 'actions',
        }),
        render: (_, record) => (
          <Link to={`/edit-vehicle/${record.Make}/${record.Model}/${record.Type}/${record.NoOfVehicles}/`}>
            <Button type="text" icon={<EditOutlined />} />
          </Link>
        ),
      },
      {
        title: () => (
          <Button 
            style={{ color: '#fff' }} 
            type="button" 
            onClick={handleSettingOpen} 
            icon={<SettingOutlined />} 
          />
        ),
        key: 'delete',
        onHeaderCell: () => ({
          className: 'actions',
        }),
        onCell: () => ({
          className: 'actions',
        }),
        render: (_, record) => (
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(`${record.Make}-${record.Model}`)}
          >
            <Button type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      }
    ]);
  }, [columnsOrder, columnsVisibility, sortField, sortOrder, handleDelete, handleFilterOpen, handleSettingOpen, moveColumn]);

  // Memoized pagination component
  const CustomPagination = useMemo(() => {
    const totalRecords = getFilteredData.length;
    const totalPages = Math.max(1, Math.ceil(totalRecords / PAGE_SIZE));
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalRecords);

    return (
      <Flex className="pagination" align="center" justify="space-between" style={{ marginTop: 0 }}>
        <Flex gap="0">
          <Button 
            icon={<StepBackwardOutlined />} 
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1 || totalRecords === 0} 
          />
          <Button 
            icon={<CaretLeftOutlined />} 
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1 || totalRecords === 0} 
          />
          <Button type="primary">
            {totalRecords === 0 ? 0 : currentPage}
          </Button>
          <Button 
            icon={<CaretRightOutlined />} 
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages || totalRecords === 0} 
          />
          <Button 
            icon={<StepForwardOutlined />} 
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages || totalRecords === 0} 
          />
        </Flex>
        <span>
          {totalRecords === 0 
            ? 'No items' 
            : `${startIndex + 1}-${endIndex} of ${totalRecords} items`}
        </span>
      </Flex>
    );
  }, [currentPage, getFilteredData.length]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="vehicle-list">
        <Flex vertical>
          <Flex align="end" style={{ marginBottom: 0 }}>
            <Flex vertical>
              <Title level={1} style={{ marginBottom: 5 }}>Vehicle Makes and Models</Title>
              <Paragraph>Makes and Models for the Vehicles</Paragraph>
            </Flex>
          </Flex>

          <Flex align="end" style={{ marginBottom: 16 }}>
            <Input.Search
              placeholder="Search by Make, Model or Type"
              onSearch={handleSearch}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 235 }}
              allowClear
              value={searchText}
            />
             <Flex style={{ marginLeft: 'auto' }}>
              <Link to="/add-vehicle">
                <Button type="primary">Add +</Button>
              </Link>
            </Flex>
          </Flex>
          <div className='tableContainer'>
          <Table
              columns={columns}
              dataSource={processedData}
              pagination={false}
              rowKey={(record) => `${record.Make}-${record.Model}-${record.Type}`} // More unique key
              className="records-table"
              bordered
              components={{
                body: {
                  row: DraggableTableRow,
                },
              }}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
            />
          {CustomPagination}
          </div>
        </Flex>
        {/* Settings Modal */}
        <Modal
        title="Select which fields to show in the list"
        open={isSettingModalVisible}
        onCancel={handleSettingClose}
        className='setting-modal'
        footer={[   
          <Flex key="footer" gap={10} justify="space-between">    
            <Flex gap={10}>
              <Button key="setting" type="primary" onClick={handleSaveSettings}>Save</Button>
              <Button key="cancel" onClick={handleSettingClose}>Cancel</Button>
            </Flex>
            <Button key="clear" onClick={handleResetSettings}>Reset</Button>
          </Flex>
        ]}
      >
        <Table
          className='table-setting'
          columns={[
            {
              title: '',
              className: 'action',
              dataIndex: 'visibility',
              key: 'visibility',
              render: (_, record) => (
                <Checkbox
                  checked={tempColumnsVisibility[record.columnKey]}
                  onChange={() => handleColumnVisibilityChange(record.columnKey)}
                />
              ),
            },
            {
              title: 'Field',
              dataIndex: 'columnName',
              key: 'columnName',
              render: (text) => <span>{text}</span>,
            },
          ]}
          dataSource={columnsOrder.map((columnKey) => ({
            key: columnKey,
            columnKey,
            columnName: columnKey === 'NoOfVehicles' ? 'No Of Vehicles' : columnKey,
          }))}
          pagination={false}
          showHeader={true}
          bordered
          size="small"
          rowClassName={(record) => {
            // Add 'active' class to the row if the checkbox is checked for that row
            return tempColumnsVisibility[record.columnKey] ? 'active' : '';
          }}
        />
      </Modal>


        {/* Filter Modal */}
        <Modal
          title={`Filter ${currentColumn}`}
          open={isFilterModalVisible}
          onCancel={() => setIsFilterModalVisible(false)}
          footer={[
            <Button key="clear" onClick={clearFilter}>
              Clear Filter
            </Button>,
            <Button key="filter" type="primary" onClick={applyFilter}>
              Apply Filter
            </Button>,
          ]}
        >
          <Select
            style={{ width: '100%', marginBottom: 16 }}
            value={filterType}
            onChange={(value) => setFilterType(value)}
          >
            <Option value="startWith">Starts With</Option>
            <Option value="contain">Contains</Option>
            <Option value="isEqual">Equals</Option>
            <Option value="notEqual">Does Not Equal</Option>
          </Select>
          <Input
            placeholder="Filter value"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Modal>
      </div>
    </DndProvider>
  );
};

export default VehicleList;