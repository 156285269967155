import axios from 'axios';
import { useState, useEffect } from 'react';

export default function eCoachVMSApi(){

    const logout = () => {
        localStorage.clear();
        //navigate('/');
    }

    const baseURL = axios.create({
        baseURL:process.env.REACT_APP_API_URL,
        headers:{
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Headers': 'Content-Type, Authorization',
           'Access-Control-Allow-Methods': "GET,HEAD,POST,OPTIONS",
           "Content-Type": "application/json",
           "Access-Control-Max-Age": "86400",
       }
   });

    
    return {
        baseURL,
        logout,
    }
    
}