import React, { useState, useMemo } from 'react';
import { Table, Input, Button, Flex, Typography, Modal, Select, Tooltip } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import {
  EditOutlined,
  DeleteOutlined,
  StepBackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  StepForwardOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  FilterOutlined,
  HolderOutlined
} from '@ant-design/icons';
import { DndProvider } from 'react-dnd'; // Drag and drop related imports
import { HTML5Backend } from 'react-dnd-html5-backend'; // The HTML5 drag-and-drop backend
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const PAGE_SIZE = 25;

// Define the type for a table row
const ITEM_TYPE = 'ROW';

// Draggable table row component
const DraggableTableRow = ({ index, moveRow, children, ...restProps }) => {
  const [, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index }, // Pass the row index to use as reference
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: (item) => {
      if (item.index !== index) {
        moveRow(item.index, index); // Move the row
        item.index = index; // Update the dragged row's index
      }
    },
  });

  return (
    <tr ref={(node) => drag(drop(node))} {...restProps}>
      {children}
    </tr>
  );
};

const Index = () => {
  const [data] = useState([
    { key: '1', order: 1, taskLocation: 'Additional Equipment' },
    { key: '2', order: 2, taskLocation: 'Brake System' },
    { key: '3', order: 3, taskLocation: 'Cab Items' },
  ]);

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [columnsOrder] = useState(['order', 'taskLocation']);

  // Modal and filter state
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filterState, setFilterState] = useState({
    type: 'startWith',
    value: '',
    column: 'taskLocation',
  });
  const [filteredData, setFilteredData] = useState(data);

  // Handle delete function
  const handleDelete = (key) => {
    alert('Delete:', key);
    // Filter out the record with the corresponding key
    setFilteredData(filteredData.filter(record => record.key !== key));
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  const handleFilterOpen = (column) => {
    if (column === 'taskLocation') {  // Apply filter only for TaskLocation column
      setFilterState({ ...filterState, column });
      setIsFilterModalVisible(true);
    }
  };

  const handleFilterClose = () => {
    setIsFilterModalVisible(false);
  };

  // Apply the filter logic specifically for 'taskLocation' column
  const applyFilter = () => {
    const { type, value, column } = filterState;
    let result = [...data]; // Use data instead of locationData

    if (value.trim() !== '') {
      const filterLower = value.toLowerCase();

      if (column === 'taskLocation') {
        const filterFunctions = {
          startWith: (record) => record[column]?.toLowerCase().startsWith(filterLower),
          contain: (record) => record[column]?.toLowerCase().includes(filterLower),
          isEqual: (record) => record[column]?.toLowerCase() === filterLower,
          notEqual: (record) => record[column]?.toLowerCase() !== filterLower
        };

        result = result.filter(filterFunctions[type]);
      }
    }

    setFilteredData(result);
    setIsFilterModalVisible(false); // Close modal after applying filter
  };

  // Clear the filter
  const clearFilter = () => {
    setFilterState({ type: 'startWith', value: '', column: 'taskLocation' });
    setFilteredData(data); // Reset data to original state
    setIsFilterModalVisible(false); // Close modal
  };

  // Handle sorting for specific columns
  const handleSort = (field) => {
    let order = 'ascend';

    if (sortField === field) {
      // If the same column is clicked again, cycle through the states
      if (sortOrder === 'ascend') {
        order = 'descend'; // Second click: descending
      } else if (sortOrder === 'descend') {
        order = null; // Third click: reset to no sorting
      } else {
        order = 'ascend'; // First click: ascending
      }
    }

    setSortField(field);
    setSortOrder(order);
  };

  const processedData = useMemo(() => {
    let result = filteredData;

    if (sortField && sortOrder) {
      result = [...result].sort((a, b) => {
        let comparison = 0;
        if (typeof a[sortField] === 'string') {
          comparison = a[sortField]?.localeCompare(b[sortField]);
        } else {
          comparison = a[sortField] - b[sortField];
        }
        return sortOrder === 'ascend' ? comparison : -comparison;
      });
    }
    return result;
  }, [filteredData, sortField, sortOrder]);

  const totalPages = Math.ceil(processedData.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, processedData.length);
  const currentData = processedData.slice(startIndex, endIndex);


  // Move row function (used by drag-and-drop)
  const moveRow = (fromIndex, toIndex) => {
    const reorderedData = [...filteredData];
    const draggedRow = reorderedData[fromIndex];
    reorderedData.splice(fromIndex, 1);
    reorderedData.splice(toIndex, 0, draggedRow);

    // Update the 'order' field based on the new index
    reorderedData.forEach((item, index) => {
      item.order = index + 1; // Recalculate order value based on the new position
    });

    setFilteredData(reorderedData);
  };

  const CustomPagination = () => (
    <Flex className='pagination' align="center" justify="space-between" style={{ marginTop: 0 }}>
      <Flex gap="0">
        <Button icon={<StepBackwardOutlined />} onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Button icon={<CaretLeftOutlined />} onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))} disabled={currentPage === 1} />
        <Button type="primary">{currentPage}</Button>
        <Button icon={<CaretRightOutlined />} onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))} disabled={currentPage === totalPages} />
        <Button icon={<StepForwardOutlined />} onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
      </Flex>
      <span className='sm-hide'>{`${startIndex + 1}-${endIndex} of ${filteredData.length} items`}</span>
    </Flex>
  );

  // Sorting arrow icon logic
  const renderColumnTitle = (columnKey) => {
    // Determine if the current column is being sorted
    const isSorted = sortField === columnKey;
    const sortIcon = isSorted
      ? sortOrder === 'ascend'
        ? <CaretUpOutlined />
        : sortOrder === 'descend'
        ? <CaretDownOutlined />
        : null
      : null;

    if (columnKey === 'taskLocation') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Vehicle Task Location
          <Tooltip title="Filter" placement="top">
            <Button
              type="text"
              size="small"
              className='filter'
              icon={<FilterOutlined />}
              onClick={() => handleFilterOpen(columnKey)}
              style={{ marginLeft: 8 }}
              aria-label="Open filter options"
            />
          </Tooltip>
          {sortIcon && (
            <span style={{ marginLeft: 8 }}>
              {sortIcon}
            </span>
          )}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {columnKey}
        {sortIcon && (
          <span style={{ marginLeft: 8 }}>
            {sortIcon}
          </span>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: '',
      key: 'actions',
      onHeaderCell: (column) => ({
        className: 'actions drag',
      }),
      onCell: (record) => ({
        className: 'actions drag',
      }),
      render: (_, record, index) => (
        <HolderOutlined />
      ),
    },
    ...columnsOrder.map((columnKey) => ({
      title: renderColumnTitle(columnKey), // Use updated renderColumnTitle with sorting arrows
      dataIndex: columnKey,
      key: columnKey,
      onHeaderCell: (column) => ({
        className: `${column.key}`,
        onClick: () => handleSort(columnKey), // Trigger sorting on header click
      }),
      onCell: (record) => ({
        className: `${columnKey}`,
      }),
      render: (text, record, index) => (
        <div>{text}</div>
      ),
    })),
    {
      title: '',
      key: 'editActions',
      onHeaderCell: (column) => ({
        className: 'actions',
      }),
      onCell: (record) => ({
        className: 'actions',
      }),
      render: (_, record) => (
        <Link to={`/edit-location/${record.taskLocation}/${record.order}/`}>
          <Button type="text" icon={<EditOutlined />} />
        </Link>
      ),
    },
    {
      title: '',
      key: 'deleteActions',
      onHeaderCell: (column) => ({
        className: 'actions',
      }),
      onCell: (record) => ({
        className: 'actions',
      }),
      render: (_, record) => (
        <Button type="text" icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)} />
      ),
    },
  ];

  const handleRow = (record, index) => ({
    index,
    moveRow,
    ...record,
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex vertical className='vehicle-list task-location'>
        <Flex align="end" style={{ marginBottom: 0 }}>
          <Flex vertical>
            <Title level={1} style={{ marginBottom: 5 }}>Vehicle Task Location</Title>
            <Paragraph>The Tasks Location on the Vehicle</Paragraph>
          </Flex>
        </Flex>
        <Flex align="end" style={{ marginBottom: 15 }}>
          <Flex vertical>
            <Input.Search
              placeholder="Search by Task Zone"
              onSearch={handleSearch}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 235 }}
              allowClear
              value={searchText}
            />
          </Flex>
          <Flex style={{ marginLeft: 'auto' }}>
            <Link to="/add-location">
              <Button type="primary">Add +</Button>
            </Link>
          </Flex>
        </Flex>

        <div className='tableContainer'>
          <Table
            components={{
              body: {
                row: DraggableTableRow, // Custom component for row
              },
            }}
            rowClassName="editable-row"
            bordered
            dataSource={currentData} // Make sure to pass currentData
            columns={columns}
            rowKey="key"
            onRow={handleRow} // Pass handler to rows
            pagination={false} // Disable pagination for simplicity
          />
          <CustomPagination />
        </div>
      </Flex>

      {/* Filter Modal */}
      <Modal
        width={300}
        title="Show items with value that:"
        visible={isFilterModalVisible}
        onCancel={handleFilterClose}
        footer={[
          <Flex gap={10} justify="space-between">
            <Button key="filter" type="primary" onClick={applyFilter}>Filter</Button>
            <Button key="clear" onClick={clearFilter}>Clear</Button>
          </Flex>
        ]}
      >
        <Flex gap="middle">
          <Select
            value={filterState.type}
            onChange={(type) => setFilterState({ ...filterState, type })}
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Option value="startWith">Start with</Option>
            <Option value="contain">Contains</Option>
            <Option value="isEqual">Is equal to</Option>
            <Option value="notEqual">Is not equal to</Option>
          </Select>
          <Input
            placeholder="Enter filter value"
            value={filterState.value}
            style={{ width: '100%', marginBottom: 8 }}
            onChange={(e) => setFilterState({ ...filterState, value: e.target.value })}
          />
        </Flex>
      </Modal>
    </DndProvider>
  );
};

export default Index;
