import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Typography, Flex } from 'antd';
import { useNavigate, useParams } from 'react-router-dom'; // Using react-router for navigation

const { Title, Paragraph } = Typography;

const EditLocation = () => {
  const { order, taskLocation } = useParams(); // Extract parameters from URL
  const [location, setLocation] = useState({ Order: '', TaskLocation: '' });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch location data based on make and model (could be from state, API, etc.)
    setLocation({ Order: order, TaskLocation:  taskLocation}); // Mock data
  }, [order, taskLocation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  // Handle changes for Statutory field
  const handleStatutoryChange = (value) => {
    setLocation((prevState) => ({
      ...prevState,
      Statutory: value,
    }));
  };

  // Handle changes forType field
  const handleTypeChange = (value) => {
    setLocation((prevState) => ({
      ...prevState,
      Type: value,
    }));
  };

  const handleSave = () => {
    // Save logic here (e.g., update state or API request)
    console.log("Updated location:", location);
    navigate('/task-location'); // Navigate back to the LocationList page
  };

  const handleCancel = () => {
    navigate('/task-location'); // Navigate back to the LocationList page without saving
  };

  return (
    <Flex vertical className='vehicle-list task-location'>
          <Flex vertical style={{ marginBottom: 0 }}>
            <Title level={1} style={{ marginBottom: 5 }}>Edit: <span>{location.TaskLocation}</span></Title>
            <Paragraph>Makes and Models for the Locations</Paragraph>
          </Flex>

        <Form className='vehicle-form'>
            <Form.Item label="Task Location">
                <Input
                    name="TaskLocation"
                    value={location.TaskLocation}
                    onChange={handleChange}
                />
            </Form.Item>
            <Form.Item label="Order">
                <Input
                    name="Order"
                    value={location.Order}
                    onChange={handleChange}
                    type="number"
                />
            </Form.Item>
            <Button type="primary" onClick={handleSave}>Save Task Zone</Button>
            <Button className='cancel' onClick={handleCancel} style={{ marginLeft: 8 }}>Cancel</Button>
        </Form>
        
    </Flex>
  );
};

export default EditLocation;
