import React, { useState } from 'react';
import { Input, Button, Form, Typography, Flex } from 'antd';
import { useNavigate } from 'react-router-dom'; // Using react-router for navigation

const { Title, Paragraph } = Typography;

const AddLocation = () => {
  const [newLocation, setNewLocation] = useState({ Order: '', TaskLocation: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Save logic here (e.g., update state or API request)
    console.log("New task location saved:", newLocation);
    navigate('/task-location'); // Navigate back to the VehicleList page
  };

  const handleCancel = () => {
    navigate('/task-location'); // Navigate back to the VehicleList page without saving
  };

  return (
    <>
      <Flex vertical className='vehicle-list task-location'>
          <Flex vertical style={{ marginBottom: 0 }}>
            <Title level={1} style={{ marginBottom: 5 }}>Add: <span>Task Location</span></Title>
            <Paragraph>The Tasks Location on the Vehicle</Paragraph>
          </Flex>

        <Form className='vehicle-form'>
            <Form.Item label="Task Location">
            <Input
                name="TaskLocation"
                value={newLocation.TaskLocation}
                onChange={handleChange}
            />
            </Form.Item>
            <Form.Item label="Order">
                <Input
                    name="Order"
                    value={newLocation.order}
                    onChange={handleChange}
                    type="number"
                />
            </Form.Item>
            <Button type="primary" onClick={handleSave}>Save Task Zone</Button>
            <Button className='cancel' onClick={handleCancel} style={{ marginLeft: 8 }}>Cancel</Button>
        </Form>
        
    </Flex>
    </>
  );
};

export default AddLocation;
