import React, { useState, useEffect, useMemo } from 'react';
import { Table, Input, Button, Modal, Select } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ResizableBox } from 'react-resizable';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;
const PAGE_SIZE = 25;

// Define type for drag and drop
const ITEM_TYPE = 'ROW';

// Draggable Row Component
const DraggableTableRow = ({ index, moveRow, children, ...restProps }) => {
  const ref = React.useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Perform the move
      moveRow(dragIndex, hoverIndex);

      // Mutating item for performance (avoid expensive search operations)
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} data-handler-id={handlerId} {...restProps}>
      {children}
    </tr>
  );
};

// VehicleList Component
const VehicleList = () => {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsOrder, setColumnsOrder] = useState(['Make', 'Model', 'Type', 'NoOfVehicles']);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  // Fetch data (simulated for this example)
  useEffect(() => {
    // Simulate fetching data
    const data = [
      { key: 1, Make: 'Toyota', Model: 'Corolla', Type: 'Sedan', NoOfVehicles: 3 },
      { key: 2, Make: 'Honda', Model: 'Civic', Type: 'Sedan', NoOfVehicles: 5 },
      { key: 3, Make: 'Ford', Model: 'Focus', Type: 'Hatchback', NoOfVehicles: 2 },
      // More data...
    ];
    setOriginalData(data);
    setFilteredData(data);
  }, []);

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = filteredData[dragIndex];
    const updatedData = [...filteredData];
    updatedData.splice(dragIndex, 1);
    updatedData.splice(hoverIndex, 0, draggedRow);
    setFilteredData(updatedData);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const searchResult = originalData.filter((record) =>
      Object.values(record).some((val) =>
        String(val).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(searchResult);
    setCurrentPage(1);
  };

  const handleFilterOpen = () => {
    setIsFilterModalVisible(true);
  };

  const applyFilter = () => {
    let result = [...originalData];
    if (filterValue.trim() !== '') {
      result = result.filter((record) =>
        Object.values(record).some((val) =>
          String(val).toLowerCase().includes(filterValue.toLowerCase())
        )
      );
    }
    setFilteredData(result);
    setIsFilterModalVisible(false);
  };

  const clearFilter = () => {
    setFilteredData(originalData);
    setIsFilterModalVisible(false);
  };

  const handleColumnDrag = (fromKey, toKey) => {
    const fromIndex = columnsOrder.indexOf(fromKey);
    const toIndex = columnsOrder.indexOf(toKey);
    
    const newColumnsOrder = [...columnsOrder];
    const [removed] = newColumnsOrder.splice(fromIndex, 1);
    newColumnsOrder.splice(toIndex, 0, removed);
    
    setColumnsOrder(newColumnsOrder);
  };

  const columns = useMemo(() => [
    { title: 'Make', dataIndex: 'Make', key: 'Make' },
    { title: 'Model', dataIndex: 'Model', key: 'Model' },
    { title: 'Type', dataIndex: 'Type', key: 'Type' },
    { title: 'NoOfVehicles', dataIndex: 'NoOfVehicles', key: 'NoOfVehicles' },
  ], []);

  const processedData = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return filteredData.slice(startIndex, startIndex + PAGE_SIZE);
  }, [filteredData, currentPage]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <Input
          placeholder="Search..."
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: '300px', marginBottom: '10px' }}
        />

        <Table
          columns={columnsOrder.map((colKey) => {
            const column = columns.find((col) => col.key === colKey);
            return {
              ...column,
              title: (
                <div
                  style={{ cursor: 'move' }}
                  onDragEnd={() => handleColumnDrag(colKey, columnsOrder[columnsOrder.indexOf(colKey) + 1] || columnsOrder[columnsOrder.indexOf(colKey) - 1])}
                >
                  {column.title}
                </div>
              ),
            };
          })}
          dataSource={processedData}
          rowKey="key"
          components={{
            body: {
              row: DraggableTableRow,
            },
          }}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          pagination={false}
        />

        <Modal
          visible={isFilterModalVisible}
          onCancel={clearFilter}
          onOk={applyFilter}
        >
          <Input
            placeholder="Enter value to filter"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Modal>
      </div>
    </DndProvider>
  );
};

export default VehicleList;
