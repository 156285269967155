import React, { useState, useRef } from 'react';
import { Input, Button, Form, Typography, Col, Row, Modal, Table, Checkbox, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightCircleFilled, StepBackwardOutlined, CaretLeftOutlined, CaretRightOutlined, StepForwardOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const PAGE_SIZE = 21; // Set the page size for pagination

const AddVehicle = () => {
  const [newVehicle, setNewVehicle] = useState({ Make: '', Model: '', Type: '', Statutory: '', NoOfVehicles: '', selectedVehicles: [] });
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const navigate = useNavigate();

  const novhicleSelectRef = useRef(null);

  // Example vehicle data
  const vehicles = [
    { id: 'PT64PAN', color: 'Red', type: '', make: '', model: '' },
    { id: 'NL17YCK', color: 'White', type: 'Executive Coach', make: 'Mercedes-Benz', model: 'Unvi' },
    { id: 'AB12XYZ', color: 'Blue', type: 'Midi Coach', make: 'Ford', model: 'Transit' },
    { id: 'CD34ABC', color: 'Green', type: 'Luxury Coach', make: 'Mercedes-Benz', model: 'Sprinter' },
    { id: 'EF56XYZ', color: 'Yellow', type: 'School Coach', make: 'Toyota', model: 'Coaster' },
    { id: 'GH78JKL', color: 'Black', type: 'Minibus', make: 'Volkswagen', model: 'Transporter' },
    // Add more vehicles as needed
  ];

  // Filter vehicles based on the search term
  const filteredVehicles = vehicles.filter(vehicle => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      vehicle.id.toLowerCase().includes(lowercasedSearchTerm) ||
      vehicle.color.toLowerCase().includes(lowercasedSearchTerm) ||
      vehicle.type.toLowerCase().includes(lowercasedSearchTerm) ||
      vehicle.make.toLowerCase().includes(lowercasedSearchTerm) ||
      vehicle.model.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // Calculate pagination indexes
  const totalPages = Math.ceil(filteredVehicles.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, filteredVehicles.length);
  const currentPageData = filteredVehicles.slice(startIndex, endIndex);

  // Custom Pagination Component
  const CustomPagination = () => (
    <Flex className='pagination' align="center" justify="space-between">
      <Flex gap="0">
        <Button
          icon={<StepBackwardOutlined />}
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        />
        <Button
          icon={<CaretLeftOutlined />}
          onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
        />
        <Button type="primary">{currentPage}</Button>
        <Button
          icon={<CaretRightOutlined />}
          onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
          disabled={currentPage === totalPages}
        />
        <Button
          icon={<StepForwardOutlined />}
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Flex>
      <span className='sm-hide'>{`${startIndex + 1}-${endIndex} of ${filteredVehicles.length} items`}</span>
    </Flex>
  );

  // Handle changes for Make, Model, Type, Statutory
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStatutoryChange = (value) => {
    setNewVehicle((prevState) => ({
      ...prevState,
      Statutory: value,
    }));
  };

  const handleTypeChange = (value) => {
    setNewVehicle((prevState) => ({
      ...prevState,
      Type: value,
    }));
  };

  const handleSave = () => {
    console.log("New vehicle saved:", newVehicle);
    navigate('/vehicle');
  };

  const handleCancel = () => {
    navigate('/vehicle');
  };

  // Modal visibility logic
  const handleAddVehiclesClick = () => {
    setModalVisible(true);
  };

  const handleFilterClose = () => {
    setModalVisible(false);
  };

  // Handle checkbox change to select vehicles
  const handleCheckboxChange = (vehicleId, e) => {
    if (e.target.checked) {
      setSelectedVehicles((prevState) => [...prevState, vehicleId]);
    } else {
      setSelectedVehicles((prevState) => prevState.filter(id => id !== vehicleId));
    }
  };

  // Save selected vehicles and update NoOfVehicles count
  const handleSaveSelectedVehicles = () => {
    setNewVehicle((prevState) => ({
      ...prevState,
      selectedVehicles: selectedVehicles,
      NoOfVehicles: selectedVehicles.length,
    }));

    if (novhicleSelectRef.current) {
      novhicleSelectRef.current.innerHTML = '';
      selectedVehicles.forEach((vehicleId) => {
        const option = document.createElement('option');
        option.value = vehicleId;
        option.textContent = vehicleId;
        novhicleSelectRef.current.appendChild(option);
      });
    }

    console.log('Selected Vehicles:', selectedVehicles);
    setModalVisible(false);
  };

  // Reset selected vehicles (called when "Reset" button is clicked)
  const handleResetSelectedVehicles = () => {
    setSelectedVehicles([]);
    console.log('Selection reset');
  };

  // Table columns
  const columns = [
    {
      title: '',
      onHeaderCell: (column) => ({
        className: 'check-col'
      }),
      onCell: (record) => ({
        className: 'check-col'
      }),
      render: (_, record) => (
        <div className='check-arrow'>
          <Checkbox
            checked={selectedVehicles.includes(record.id)}
            onChange={(e) => handleCheckboxChange(record.id, e)}
          />
          <RightCircleFilled />
        </div>
      ),
    },
    {
      title: 'Vehicle ID',
      dataIndex: 'id',
    },
    {
      title: 'Vehicle Color',
      dataIndex: 'color',
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'type',
    },
    {
      title: 'Vehicle Make',
      dataIndex: 'make',
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'model',
    },
  ];

  return (
    <>
      <div className="vehicle-list">
        <div style={{ marginBottom: 0 }}>
          <Title level={1} style={{ marginBottom: 5 }}>Add: <span>New Vehicle</span></Title>
          <Paragraph>Makes and Models for the Vehicles</Paragraph>
        </div>

        <Form className="vehicle-form">
          <Form.Item label="Make">
            <Input
              name="Make"
              value={newVehicle.Make}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Model">
            <Input
              name="Model"
              value={newVehicle.Model}
              onChange={handleChange}
            />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={16} md={19}>
              <Form.Item label="Type">
                <select
                  value={newVehicle.Type}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="Minibus">Minibus</option>
                  <option value="Midi Coach">Midi Coach</option>
                  <option value="Executive Coach">Executive Coach</option>
                  <option value="Standard Coach">Standard Coach</option>
                  <option value="Double Decker">Double Decker</option>
                  <option value="Luxury Coach">Luxury Coach</option>
                  <option value="Double-Decker Coach">Double-Decker Coach</option>
                  <option value="Sleeper Coach">Sleeper Coach</option>
                  <option value="Intercity Coach">Intercity Coach</option>
                  <option value="Tourist Coach">Tourist Coach</option>
                  <option value="School Coach">School Coach</option>
                  <option value="Airport Shuttle Coach">Airport Shuttle Coach</option>
                  <option value="Hybrid Coach">Hybrid Coach</option>
                  <option value="Electric Coach">Electric Coach</option>
                </select>
              </Form.Item>
              <Form.Item label="Statutory">
                <select
                  value={newVehicle.Statutory}
                  onChange={(e) => handleStatutoryChange(e.target.value)}
                >
                  <option value="">Select Statutory Type</option>
                  <option value="Motor Vehicle">Motor Vehicle</option>
                  <option value="PSV">PSV</option>
                  <option value="Trailer">Trailer</option>
                  <option value="Lorry">Lorry</option>
                  <option value="Truck">Truck</option>
                </select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={5} className="NoVehicles">
              <Form.Item label="Types of these Vehicles in Fleet">
                <Input
                  name="NoOfVehicles"
                  value={newVehicle.NoOfVehicles}
                  onChange={handleChange}
                  type="number"
                  disabled
                />
              </Form.Item>
              <select id="novhicleSelect" ref={novhicleSelectRef} multiple></select>
              <Button type="primary" onClick={handleAddVehiclesClick}>Add vehicles</Button>
            </Col>
          </Row>
          <Button type="primary" onClick={handleSave}>Save Make/Model</Button>
          <Button className="cancel" onClick={handleCancel} style={{ marginLeft: 8 }}>Cancel</Button>
        </Form>
      </div>

      {/* Modal for adding vehicles */}
      <Modal
        title="Select Vehicles"
        visible={modalVisible}
        footer={null}
        className="vehicleModal"
      >
        <Flex className='search_form' align="end" style={{ marginBottom: 15 }}>
          <Input.Search
            placeholder="ID, Color, Type, Make, Model"
            style={{ width: 235 }}
            allowClear
            onSearch={(value) => setSearchTerm(value)}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term as user types
          />
          <Flex gap={10} style={{ marginLeft: 'auto' }}>
            <Button className='button' type="primary" onClick={handleSaveSelectedVehicles}>Ok</Button>
            <Flex gap={10}>
              <Button className='button cancel' onClick={handleResetSelectedVehicles}>Reset</Button>
              <Button className='button cancel' onClick={handleFilterClose}>Cancel</Button>
            </Flex>
          </Flex>
        </Flex>
        <div className='tableContainer'>
          <Table
            columns={columns}
            dataSource={currentPageData} // Use currentPageData to display paginated data
            rowKey="id"
            bordered
            pagination={false}
            rowClassName={(record) => (selectedVehicles.includes(record.id) ? 'active' : '')}
          />
          <CustomPagination />
        </div>
        <Flex gap={10} style={{ marginTop: 15 }} justify='space-between'>
          <Button className='button' type="primary" onClick={handleSaveSelectedVehicles}>Ok</Button>
          <Flex gap={10}>
            <Button className='button cancel' onClick={handleResetSelectedVehicles}>Reset</Button>
            <Button className='button cancel' onClick={handleFilterClose}>Cancel</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default AddVehicle;
