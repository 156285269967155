import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Typography, Col, Row, Modal, Table, Checkbox } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';
import { StepBackwardOutlined, CaretLeftOutlined, CaretRightOutlined, StepForwardOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

// Example vehicle data
const vehicles = [
  { id: 'PT64PAN', color: 'Red', type: '', make: '', model: '' },
  { id: 'NL17YCK', color: 'White', type: 'Executive Coach', make: 'Mercedes-Benz', model: 'Unvi' },
  { id: 'AB12XYZ', color: 'Blue', type: 'Midi Coach', make: 'Ford', model: 'Transit' },
  { id: 'CD34ABC', color: 'Green', type: 'Luxury Coach', make: 'Mercedes-Benz', model: 'Sprinter' },
  { id: 'EF56XYZ', color: 'Yellow', type: 'School Coach', make: 'Toyota', model: 'Coaster' },
  { id: 'GH78JKL', color: 'Black', type: 'Minibus', make: 'Volkswagen', model: 'Transporter' },
];

const PAGE_SIZE = 21;

const EditVehicle = () => {
  const { make, model, type, NoOfVehicles } = useParams(); 
  const [vehicle, setVehicle] = useState({ Make: '', Model: '', Type: '', Statutory: '', NoOfVehicles: '' });
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState(['PT64PAN', 'NL17YCK']);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const navigate = useNavigate();

  useEffect(() => {
    setVehicle({ Make: make, Model: model, Type: type, NoOfVehicles: NoOfVehicles });
  }, [make, model, type, NoOfVehicles]);

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.id.includes(searchTerm) ||
    vehicle.color.includes(searchTerm) ||
    vehicle.type.includes(searchTerm) ||
    vehicle.make.includes(searchTerm) ||
    vehicle.model.includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredVehicles.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, filteredVehicles.length);
  const currentPageData = filteredVehicles.slice(startIndex, endIndex);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    console.log("Updated vehicle:", vehicle);
    navigate('/vehicle'); 
  };

  const handleCancel = () => {
    navigate('/vehicle');
  };

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleVehicleSelection = (id) => {
    setSelectedVehicles((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((vehicleId) => vehicleId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSaveSelectedVehicles = () => {
    setVehicle((prevState) => ({
      ...prevState,
      NoOfVehicles: selectedVehicles.length,
    }));
    setModalVisible(false);
  };

  const handleResetSelectedVehicles = () => {
    setSelectedVehicles([]);
  };

  const handleFilterClose = () => {
    setModalVisible(false);
  };

  // Define handleTypeChange and handleStatutoryChange
  const handleTypeChange = (value) => {
    setVehicle((prevState) => ({
      ...prevState,
      Type: value,
    }));
  };

  const handleStatutoryChange = (value) => {
    setVehicle((prevState) => ({
      ...prevState,
      Statutory: value,
    }));
  };

  const columns = [
    {
      title: '',
      render: (text, record) => (
        <div className='check-arrow'>
          <Checkbox
            checked={selectedVehicles.includes(record.id)}
            onChange={() => handleVehicleSelection(record.id)}
          />
          <RightCircleFilled />
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    }
  ];

  const CustomPagination = () => (
    <Flex className="pagination" align="center" justify="space-between">
      <Flex gap="0">
        <Button
          icon={<StepBackwardOutlined />}
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        />
        <Button
          icon={<CaretLeftOutlined />}
          onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
        />
        <Button type="primary">{currentPage}</Button>
        <Button
          icon={<CaretRightOutlined />}
          onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
          disabled={currentPage === totalPages}
        />
        <Button
          icon={<StepForwardOutlined />}
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Flex>
      <span className="sm-hide">{`${startIndex + 1}-${endIndex} of ${filteredVehicles.length} items`}</span>
    </Flex>
  );

  return (
    <Flex vertical className="vehicle-list">
      <Flex vertical style={{ marginBottom: 0 }}>
        <Title level={1} style={{ marginBottom: 5 }}>
          Edit: <span>{vehicle.Make} {vehicle.Model}</span>
        </Title>
        <Paragraph>Makes and Models for the Vehicles</Paragraph>
      </Flex>

      <Form className="vehicle-form">
        <Form.Item label="Make">
          <Input
            name="Make"
            value={vehicle.Make}
            onChange={handleChange}
            placeholder="Enter vehicle make"
          />
        </Form.Item>
        <Form.Item label="Model">
          <Input
            name="Model"
            value={vehicle.Model}
            onChange={handleChange}
            placeholder="Enter vehicle model"
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={16} md={19}>
            <Form.Item label="Type">
              <select
                  value={vehicle.Type}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="Minibus">Minibus</option>
                  <option value="Midi Coach">Midi Coach</option>
                  <option value="Executive Coach">Executive Coach</option>
                  <option value="Standard Coach">Standard Coach</option>
                  <option value="Double Decker">Double Decker</option>
                  <option value="Luxury Coach">Luxury Coach</option>
                  <option value="Double-Decker Coach">Double-Decker Coach</option>
                  <option value="Sleeper Coach">Sleeper Coach</option>
                  <option value="Intercity Coach">Intercity Coach</option>
                  <option value="Tourist Coach">Tourist Coach</option>
                  <option value="School Coach">School Coach</option>
                  <option value="Airport Shuttle Coach">Airport Shuttle Coach</option>
                  <option value="Hybrid Coach">Hybrid Coach</option>
                  <option value="Electric Coach">Electric Coach</option>
                </select>
            </Form.Item>
            <Form.Item label="Statutory">
              <select
                  value={vehicle.Statutory}
                  onChange={(e) => handleStatutoryChange(e.target.value)}
                >
                  <option value="">Select Statutory Type</option>
                  <option value="Motor Vehicle">Motor Vehicle</option>
                  <option value="PSV">PSV</option>
                  <option value="Trailer">Trailer</option>
                  <option value="Lorry">Lorry</option>
                  <option value="Truck">Truck</option>
                </select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={5} className="NoVehicles">
            <Form.Item label="Types of these Vehicles in Fleet">
              <Input
                name="NoOfVehicles"
                value={vehicle.NoOfVehicles}
                onChange={handleChange}
                type="number"
                readOnly
              />
            </Form.Item>
            <select id="novhicleSelect" multiple value={selectedVehicles}>
              {vehicles
                .filter(vehicle => selectedVehicles.includes(vehicle.id))
                .map(vehicle => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.id}
                  </option>
                ))}
            </select>
            <Button type="primary" onClick={handleShowModal}>
              Add vehicles
            </Button>
          </Col>
        </Row>

        <Button type="primary" onClick={handleSave}>
          Save Make/Model
        </Button>
        <Button className="cancel" onClick={handleCancel} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </Form>

      {/* Modal for selecting vehicles */}
      <Modal
        title={
          <h2>
            Edit: <span>{vehicle.Make} {vehicle.Model}</span>
          </h2>
        }
        visible={modalVisible}
        onCancel={handleFilterClose}
        footer={null}
        className="vehicleModal"
      >
        <Flex className="search_form" align="end" style={{ marginBottom: 15 }}>
          <Input.Search
            placeholder="ID, Color, Type, Make, Model"
            style={{ width: 235 }}
            allowClear
            onSearch={(value) => setSearchTerm(value)}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Flex gap={10} style={{ marginLeft: 'auto' }}>
            <Button className="button" type="primary" onClick={handleSaveSelectedVehicles}>
              Ok
            </Button>
            <Flex gap={10}>
              <Button className="button cancel" onClick={handleResetSelectedVehicles}>
                Reset
              </Button>
              <Button className="button cancel" onClick={handleFilterClose}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <div className="tableContainer">
          <Table
            columns={columns}
            dataSource={currentPageData}
            rowKey="id"
            bordered
            pagination={false}
            rowClassName={(record) =>
              selectedVehicles.includes(record.id) ? 'active' : ''
            }
          />

          <CustomPagination />
        </div>
        <Flex gap={10} style={{ marginTop: 15 }} justify='space-between'>
          <Button className='button' type="primary" onClick={handleSaveSelectedVehicles}>Ok</Button>
          <Flex gap={10}>
            <Button className='button cancel' onClick={handleResetSelectedVehicles}>Reset</Button>
            <Button className='button cancel' onClick={handleFilterClose}>Cancel</Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default EditVehicle;
