import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Flex, Input, Drawer, Image  } from 'antd';
import { HomeFilled, ToolFilled, QuestionCircleFilled } from '@ant-design/icons';
import useScreenSize from '../../utils/useScreenSize';

import './sidebar.css';

import LogoMain from '../../images/Logo.png';
import LogoIcon from '../../images/logo-icon.png';

const { Sider } = Layout;
const { Search } = Input;

const Sidebar = ({ collapsed, open, onClose }) => {
  const navigate = useNavigate();

  const { isMobile, isTablet } = useScreenSize();

  const menuItems = [
    {
      key: '/',
      icon: <HomeFilled />,
      label: 'Home'
    },
    {
      key: '/tools',
      icon: <ToolFilled />,
      label: 'Tools',
      children: [
        { key: '/vehicle', label: 'Vehicle List' },
        { key: '/task-location', label: 'Vehicle Task Location' },
        { key: '/settings', label: 'Settings' }
      ]
    },
    {
      key: '/help',
      icon: <QuestionCircleFilled />,
      label: 'Help'
    }
  ];

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  return (<>
    {isMobile != true && isTablet != true ? (<>
      <Sider width={220} trigger={null} collapsible collapsed={collapsed} style={{ backgroundColor: '#f8fafb' }}>
        <Flex align="center" justify="center" style={{ padding: 5, backgroundColor: '#3382b0', textAlign: 'center', minHeight: '51px' }}>
          {!collapsed ? (
            <Image src={LogoMain} preview={false} style={{ height: '40px' }} />
          ) : (
            <Image src={LogoIcon} preview={false} style={{ height: '32px' }} />
          )}
        </Flex>
        
        {!collapsed && (
          <div className='sidemenu' style={{ padding: '15px 12px 15px 12px', marginBottom: 2, borderBottom: '1px solid #dbdbdb' }}>
            <h3 className="name">Hello, Torben</h3>
            <Search placeholder="Search..." />
          </div>
        )}

        <Menu className="sidebar-menus" mode="inline" items={menuItems} onClick={handleMenuClick} style={{ backgroundColor: 'transparent', border: '0 none' }} />
      </Sider>
     </>) : (
        <Drawer
          placement="left"
          width={280}
          onClose={onClose}
          open={open}
          className="mobile-drawer-sidebar"
        > 

          <Flex align="center" justify="center" style={{ padding: 5, backgroundColor: '#3382b0', textAlign: 'center', minHeight: '51px' }}>
              <Image src={LogoMain} preview={false} style={{ height: '40px' }} />
          </Flex>

          <div style={{ padding: '15px 12px 15px 12px', marginBottom: 2, borderBottom: '1px solid #dbdbdb' }}>
            <h3 className="name">Hello, Torben</h3>
            <Search placeholder="Search..." />
          </div>

          <Menu className="sidebar-menus" mode="inline" items={menuItems} onClick={handleMenuClick} style={{ backgroundColor: 'transparent', border: '0 none' }} />

        </Drawer>
      )
    }
  </>);
};

export default Sidebar;