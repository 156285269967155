import React, { useState, useEffect } from 'react';
import { Layout, Avatar, Image, Flex, Badge, Space, Dropdown, Button } from 'antd';
import { MenuOutlined, UserOutlined, MailFilled } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

import useScreenSize from '../utils/useScreenSize';
import LogoIcon from '../images/logo-icon.png';

const { Header, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [time, setTime] = useState(100000);
  const [isRunning] = useState(true);

  const { isMobile, isTablet, isDesktop, isTabletToMobile } = useScreenSize();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let interval;
    if (isRunning && time > 0) {
      interval = setInterval(() => setTime(t => t - 1), 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isRunning, time]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const getCurrentDate = () => {
  return new Date().toLocaleDateString('en-GB', {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  }).replace(',', '').replace('.', '');
};



  const userMenuItems = [
    { key: '1', label: 'Profile' },
    { key: '2', label: 'Settings' },
    { key: '3', label: 'Logout' }
  ];

  const toggleClickMenu = () => {
    if (isDesktop == true){
      setCollapsed(!collapsed)
    } else if (isTabletToMobile == true){
      setOpen(true);
    } else{
      setCollapsed(!collapsed)
    }
  }

  const onClose = () => {
    setOpen(false);
  };

  const [notifications] = useState([
    {
      id: 1,
      title: 'New Message',
      description: 'You have received a new message from John',
      time: '5 min ago',
      read: false
    },
    {
      id: 2,
      title: 'System Update',
      description: 'System maintenance scheduled for tomorrow',
      time: '1 hour ago',
      read: false
    },
    {
      id: 3,
      title: 'System Update',
      description: 'System maintenance scheduled for tomorrow',
      time: '1 hour ago',
      read: false
    }
  ]);

  const unreadCount = notifications.filter(notif => !notif.read).length;

  const items = notifications.map(notification => ({
    key: notification.id,
    label: (
      <div className="notification-item" style={{ padding: '8px 0' }}>
        <div style={{ fontWeight: !notification.read ? 'bold' : 'normal' }}>
          {notification.title}
        </div>
        <div style={{ fontSize: '12px', color: '#666' }}>
          {notification.description}
        </div>
        <div style={{ fontSize: '11px', color: '#999', marginTop: '4px' }}>
          {notification.time}
        </div>
      </div>
    )
  }));

  const dropdownRender = () => (
    <div style={{ 
      backgroundColor: 'white', 
      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
      padding: '8px 0',
      left: isMobile ? 50 : 0,
      position: 'relative',
      width: isMobile ? '255px' : '300px'
    }}>
      <div style={{ 
        padding: '8px 16px',
        borderBottom: '1px solid #f0f0f0',
        fontWeight: 'bold'
      }}>
        Notifications ({notifications.length})
      </div>
      {notifications.length > 0 ? (
        items.map(item => (
          <div key={item.key} style={{ padding: '0 16px' }}>
            {item.label}
          </div>
        ))
      ) : (
        <div style={{ padding: '16px', textAlign: 'center', color: '#999' }}>
          No notifications
        </div>
      )}
    </div>
  );
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar collapsed={collapsed} open={open} onClose={onClose} />
      <Layout>
        <Header style={{ background: '#3b8eb9', padding: isTabletToMobile ? '0' : '0 20px', display: 'flex', justifyContent: isTabletToMobile ? '' : 'space-between', alignItems: 'center', height: '51px', lineHeight: 'inherit' }}>

          {isTabletToMobile ?
            <Flex align="center" justify="center" style={{ padding: '5px 10px', backgroundColor: '#3382b0', textAlign: 'center', minHeight: '51px', marginRight: 6 }}>
              <Image src={LogoIcon} preview={false} style={{ height: '32px' }} />
            </Flex>
            :
            ""
          }
          <Button
            className='humberge-btn'
            type="text"
            icon={<MenuOutlined style={{ fontSize: 20 }} />}
            onClick={toggleClickMenu}
            style={{ color: 'white', padding: 0, backgroundColor: 'transparent' }}
          />

          <Flex gap={14} align="center" style={{ paddingRight: isTabletToMobile ? '15px' : '', marginLeft: isTabletToMobile ? 'auto' : '' }}>
          {!isMobile &&
            <Flex vertical style={{ textAlign: 'center', minWidth: '110px' }}>
              <span style={{ color: 'white', fontSize: 16 }}>{formatTime(time)}</span>
              <span style={{ color: 'white' }}>{getCurrentDate()}</span>
            </Flex>
          }

            <Dropdown 
              dropdownRender={dropdownRender}
              trigger={['click']}
              placement="bottomRight"
              className="notification-dropdown"
            >
              <Space>
                <Badge count={unreadCount}>
                  <MailFilled style={{ fontSize: 14, color: 'white', cursor: 'pointer' }} />
                </Badge>
              </Space>
            </Dropdown>

            <Dropdown 
              menu={{ items: userMenuItems }} 
              placement="bottomRight" 
              trigger={['click']}
              dropdownRender={(menu) => (
                <div style={{ 
                  width: '150px', 
                  position: 'relative',
                  top: 0,
                }}>
                  <div style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '8px',
                    width: 0,
                    height: 0,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderBottom: '8px solid white', // Match dropdown background
                    zIndex: 1
                  }} />
                  {menu}
                </div>
              )}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, cursor: 'pointer' }}>
                <Avatar icon={<UserOutlined />} />
                <span style={{ color: 'white' }}>Torben</span>
              </div>
            </Dropdown>
          </Flex>
        </Header>
        <Content className='mainlayout' style={{ backgroundColor: '#ffffff' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;